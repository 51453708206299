<template>
  <div class="main-con">
    <div class="list-item">
      <div class="info">
        <h1>创建人</h1>
        <h1>{{ itemData.subjectName }}</h1>
      </div>
      <div class="info">
        <h1>创建时间</h1>
        <h1>{{ itemData.createTime }}</h1>
      </div>
      <div class="info">
        <h1>生产企业</h1>
        <h1>{{ itemData.subjectName }}</h1>
      </div>
      <div class="info">
        <h1>产品类型</h1>
        <h1>{{ itemData.productClassName }}</h1>
      </div>
      <div class="info">
        <h1>产品名称</h1>
        <h1>{{ itemData.productName }}</h1>
      </div>
      <div class="info">
        <h1>所属地区</h1>
        <h1>{{ itemData.fullRegionName }}</h1>
      </div>
      <div class="info">
        <h1>操作名称</h1>
        <h1>{{ itemData.recordType }}</h1>
      </div>
      <div class="info">
        <h1>操作时间</h1>
        <h1 v-if="itemData.operationTime">
          {{ itemData.operationTime.substring(0, 10) }}
        </h1>
      </div>
      <div class="info">
        <h1>操作描述</h1>
        <h1>{{ itemData.operationDes }}</h1>
      </div>
      <div class="info">
        <h1>录音</h1>
        <span v-if="!itemData.operationAudio">无</span>
        <div v-else>
          <audio
            @ended="overAudio"
            ref="audio"
            :src="itemData.operationAudio"
          />
          <div class="video-play">
            <van-button @click="playHand()" v-if="!playShow" type="primary"
              >播放</van-button
            >
            <van-button @click="suspendHand()" v-if="playShow" type="success"
              >暂停</van-button
            >
          </div>
        </div>
      </div>

      <div class="info">
        <h1>视频</h1>
        <span v-if="!itemData.operationVideo">无</span>
        <video
          v-else
          ref="videoPlayer"
          class="video video-js"
          id="video"
          controls=""
          autoplay="true"
          loop=""
          muted="muted"
          x5-video-player-type="h5"
          x5-vide=""
          o-player-fullscreen="true"
          preload="auto"
          webkit-playsinline="true"
          playsinline="true"
          :src="itemData.operationVideo"
        ></video>
      </div>
      <div class="info">
        <h1>照片</h1>
        <span
          v-if="
            itemData.operationPic == '' && itemData.operationPic.length == 0
          "
          >无</span
        >
        <div @click="imgagePre" v-else class="img-list">
          <img
            v-for="(data, idx) in itemData.operationPic"
            :key="idx"
            :src="data"
            alt=""
            style="width: 1.6rem; height: 1.6rem"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import cateApi from "@/request/api/hegezhen";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import { ImagePreview } from "vant";
export default {
  props: {},
  watch: {},
  data() {
    return {
      list: [],
      itemData: {},
      audio: new Audio(),
      playShow: false,
    };
  },
  async mounted() {
    try {
      let params = this.$route.query.params;
      let { item } = JSON.parse(params);
      let query = {
        productionRecordId: item.productionRecordId,
      };
      let response = await cateApi.zhusxq(query);
      this.itemData = response.data;
      this.itemData.operationAudio = "";
      this.itemData.operationVideo = "";

      if (response.data.operationPic != "") {
        this.itemData.operationPic = response.data.operationPic.split(",");
      }
      if (response.data.operationVideo) {
        this.player = videojs(this.$refs.videoPlayer, this.options, () => {
          this.player.log("onPlayerReady", this);
        });
      }
    } catch (error) {
      console.log(error);
    }
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
  methods: {
    //点击播放音频
    async playHand() {
      await this.$nextTick();
      this.$refs["audio"].play();
      this.playShow = true;
    },
    overAudio() {
      this.playShow = false;
    },
    //点击暂停
    suspendHand() {
      this.$refs["audio"].pause();
      this.playShow = false;
    },
    imgagePre() {
      ImagePreview(this.itemData.operationPic);
    },
  },
};
</script>

<style lang="scss" scoped>
.main-con {
  width: 100%;
  height: 100%;
  padding: 0.2rem;
  box-sizing: border-box;
  overflow: scroll;
  .list-item {
    background-color: #fff;
    border-radius: 0.16rem;
    padding: 0.2rem;
    margin-bottom: 0.2rem;
    .item-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #f1f1f1;
      padding-bottom: 0.2rem;
      span {
        &:nth-child(1) {
          font-weight: 600;
        }
        &:nth-child(2) {
          color: #33bd7d;
          font-size: 0.24rem;
          font-weight: 600;
        }
      }
    }
    .info {
      line-height: 0.5rem;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      h1 {
        &:nth-child(1) {
          width: 20%;
        }
        &:nth-child(2) {
          color: #333;
          flex: 1;
          font-weight: 600;
        }
      }
    }
  }
}
</style>
